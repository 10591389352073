import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useSession } from '@clerk/clerk-react';

const HrAssistant = React.forwardRef((props, ref) => {
  const [userInput, setUserInput] = useState('');
  const [chatHistory, setChatHistory] = useState([]); // Complete chat history
  const [loading, setLoading] = useState(false); // Loading state for showing gif
  const [initSuccess, setInitSuccess] = useState(false);
  const chatBodyRef = useRef(null);
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const { session } = useSession();  // Use useSession to get session information

  useEffect(() => {
    // Set up initial message on page load
    const initialMessage = {
      sender: 'ASSISTANT',
      text: "I can help you find the most suitable candidates, just ask me any question about the candidates. For example: \"Rank the candidates based on their leadership\". Or, \"Who has the most experience in SaaS products?\""
    };

    // Add initial message to chat history
    setChatHistory([initialMessage]);

    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, []);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistory, loading]);

  // Function to initialize Cohere with the initial prompt and transcripts
  const initializeEmbedding = async (candidatesData) => {
    if (!session) {
      console.error("No session found");
      return;
    }
    try {
      const response = await axios.post(
        rootUrl + '/api/bData/ragInit',
        {
          candidatesData: candidatesData,
        },
        {
          headers: {
            Authorization: `Bearer ${session.id}`, // Pass the session ID in the header
          },
        }
      );

      if (response.data && response.data.status === "success") {
        console.log("[INFO] Cohere initialization completed on the backend.");
        setInitSuccess(true);
      } else {
        console.error('[ERROR] Unexpected response format:', response.data);
      }
    } catch (error) {
      console.error('[ERROR] Error during Cohere initialization:', error);
    }
  };

  // Function to handle subsequent user messages
  const handleSendMessage = async (message) => {
    if (!message.trim()) return;

    const newMessage = { sender: 'USER', text: message };
    setChatHistory(prevChatHistory => [...prevChatHistory, newMessage]);
    setUserInput('');  // Clear input after sending
    setLoading(true);  // Show loading gif

    if (!session) {
      console.error("No session found");
      return;
    }
    try {
      const response = await axios.post(rootUrl + '/api/bData/cohereMessage',
        {
          message: message,
        },
        {
          headers: {
            Authorization: `Bearer ${session.id}`, // Pass the session ID in the header
          },
        });

      console.log(response.data);
      if (response.data && response.data.data.response && response.data.data.openAI_processed_message) {
        const assistantMessage = { sender: 'ASSISTANT', text: response.data.data.openAI_processed_message };
        setChatHistory(prevChatHistory => [
          ...prevChatHistory, // Remove the loading message
          assistantMessage
        ]);
        setLoading(false); // Hide loading gif

        // Extract feedback list from the API response
        const recommendedFeedbackList = response.data.data.recommended_feedbackList;

        console.log(recommendedFeedbackList);

        // Call the prop function to pass feedbackId and annotation list to the parent
        if (props.handleSuggestedCandidates) {
          props.handleSuggestedCandidates(recommendedFeedbackList);
        }
      } else {
        console.error('Unexpected response format:', response.data);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setLoading(false); // Hide loading gif on error
    }
  };

  React.useImperativeHandle(ref, () => ({
    initializeEmbedding,
    handleSendMessage,
  }));

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage(userInput);
    }
  };

  return (
    <div style={{
      ...styles.chatContainer,
      opacity: (!initSuccess) ? 0.5 : 1,  // Change chatbox opacity when not initialized or loading
      pointerEvents: (!initSuccess || loading) ? 'none' : 'auto'  // Disable all interactions before initSuccess
    }}>
      {/* Sticky Header for the assistant avatar */}
      <div style={{ ...styles.stickyHeader, alignSelf: "flex-start" }}>
        <img
          src="/icons/assistant.png" // Change to your image path
          alt="Assistant Avatar"
          style={styles.chatHeaderImage}
        />
      </div>

      <div style={styles.chatBody} ref={chatBodyRef}>
        {chatHistory.map((message, index) => (
          <div
            key={index}
            style={message.sender === 'USER' ? styles.userMessageContainer : styles.assistantMessageContainer}
          >
            <div style={message.sender === 'USER' ? styles.userMessage : styles.assistantMessage}>
              <p style={styles.messageText}><strong>{message.sender === 'USER' ? 'You' : 'Assistant'}:</strong> {message.text}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Show loading gif outside the message div */}
      {loading && (
        <div style={styles.loadingGifContainer}>
          <img src="/icons/typing-texting.gif" alt="Loading" style={styles.loadingGif} />
        </div>
      )}

      <div style={styles.chatFooter}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          style={styles.chatInput}
          placeholder="I'm here to assist you with your queries. Ask me anything!"
          disabled={!initSuccess || loading} // Disable input when not initialized or loading
        />
        <button onClick={() => handleSendMessage(userInput)} style={styles.sendButton} disabled={!initSuccess || loading}>Send</button>
      </div>
    </div>
  );
});

const styles = {
  chatContainer: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    overflow: 'hidden',
    marginBottom: '20px'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fff',
    zIndex: 1000,
    padding: '5px',
    textAlign: 'center',
    height: '40px',
  },
  chatHeaderImage: {
    margin: '6px',
    width: '30px',
    height: '25px',
    borderRadius: '25%',
  },
  chatBody: {
    flex: 1,
    padding: '10px',
    alignItems: 'flex-center',
    overflowY: 'auto',
    maxHeight: '300px'
  },
  chatFooter: {
    padding: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  chatInput: {
    flex: 1,
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    marginRight: '10px'
  },
  sendButton: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer'
  },
  userMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '5px 0'
  },
  assistantMessageContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '5px 0'
  },
  userMessage: {
    backgroundColor: '#d1e7dd',
    padding: '5px 10px',
    borderRadius: '5px',
    maxWidth: '80%',
    fontSize: '14px',
    lineHeight: '1.4'
  },
  assistantMessage: {
    backgroundColor: '#f8d7da',
    padding: '5px 10px',
    borderRadius: '5px',
    maxWidth: '80%',
    fontSize: '14px',
    lineHeight: '1.4'
  },
  messageText: {
    margin: 0,
    fontSize: '14px'
  },
  loadingGif: {
    width: '50px',
    height: '40px',
  },
  loadingGifContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
    backgroundColor: 'transparent',
  }
};

export default HrAssistant;
