import Router from "./Router";
import "./App.css";
import "gestalt/dist/gestalt.css";
import { ClerkProvider, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";

// Import your publishable key
const PUBLISHABLE_KEY = "pk_live_Y2xlcmsubWlyd29yay5haSQ";

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

function App() {
  return (
    <div className="App">
      <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
        <SignedOut>
          <RedirectToSignIn />
        </SignedOut>
        <header className="App-header">
          <Router />
        </header>
      </ClerkProvider>
    </div>
  );
}

export default App;
