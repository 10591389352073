import React from "react";
import { Box, SideNavigation, Icon, Text, Flex } from 'gestalt';

export const SideButton = ({ onSelectStep, completedSteps, collapsed, setCollapsed}) => {
  const navigationIcons = [
    "image-portrait",
    "add-section",
    "speech-ellipsis", 
    "add-pin",
    "ads-overview",
  ]
  const navigationItems = [
    "Job Description",
    "Additional Information",
    "Question List",
    "Evaluation Criterion",
    "Review"
  ].map((label, index) => {
    const isChecked = completedSteps.includes(index);
    const itemLabel = isChecked ? (
      <span>
            <Flex
              alignItems="center"
              height="100%"
              justifyContent="between"
              width="100%"
            >
              <Text> {label} </Text>
              <Icon icon="check" color="success" size={12} accessibilityLabel="Completed" inline />
            </Flex> 
     </span>
    ) : label;

    return {
      label: itemLabel,
      icon: navigationIcons[index],
      onClick: () => onSelectStep(index),
    };
  });
  return (
      <Box height={800}>
      <SideNavigation 
        accessibilityLabel="Job post navigation"
        collapsed={collapsed}  
        onCollapse={setCollapsed}
        showBorder
      >
        {navigationItems.map((item, index) => (
          <SideNavigation.TopItem key={index} onClick={item.onClick} label={item.label} icon={item.icon}/>
        ))}
      </SideNavigation>
      </Box>
  );
}