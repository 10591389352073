import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom"
import { Box, Flex, ButtonLink, Divider, Text, Accordion, Status } from "gestalt";
import React, { useState, useEffect } from "react";
import { Radar } from "../components/Feedback/Radar";
import { Header } from "../components/Header";
import { useUser } from '@clerk/clerk-react';
import { getUser } from "../components/getUser";

const Feedback = () => {
  const { isSignedIn, user, isLoaded } = useUser(); 
  const [feedback, setFeedback] = useState({ candidate: '', overview: '', content: [], chatHistory: [] });
  const [errorMsg, setErrorMsg] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(false); 
  const params = useParams()
  const navigate = useNavigate();

  const rootUrl = process.env.REACT_APP_BACKEND_URL
  const query = new URLSearchParams(useLocation().search);
  const candidateName = query.get('name'); 

  const defaultRadarData = [
    {
        "section": "Default",
        "score (0-5)": 0,
    },
];

const [radarData, setRadarData] = useState(defaultRadarData);

  useEffect(() => {
    const fetchFeedbackData = async (id) => {
      if (isLoaded) {
        try {
          let curUser = { emailAddress: user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName }
          let userInfo = await getUser(isSignedIn, curUser);
          const feedbackRes = await axios.post(rootUrl + "/api/bData/applicantFeedback", {recruiterID: userInfo.id, feedbackId: id });
          const processedFeedbackContent = feedbackRes.data.data.feedbackContent.map((item) => {
            return {
              ...item,
              score: item.score === 'N/A' ? "0" : item.score,
            };
          });
          setFeedback({
            candidateName: candidateName,
            overview: feedbackRes.data.data.feedbackOverview,
            content: processedFeedbackContent,
            chatHistory: feedbackRes.data.data.chatHistory,
            userEmail: feedbackRes.data.data.userEmail,
          });
          setIsAuthorized(true);
        } catch (error) {
          if (error.response && error.response.status === 403) {
            setIsAuthorized(false);
            alert("Unauthorized data access!");}
        }
      }
    };


    if (params.id === undefined) {
      setErrorMsg("No Valid Feedback ID")
    } else {
      fetchFeedbackData(params.id);
    }
  }, [params.id, rootUrl, candidateName, isLoaded, isSignedIn, user]);

  useEffect(() => {
    if (feedback.content.length > 0) {
      const newRadarData = feedback.content.map(section => ({
        "section": section.section,
        "score (0-5)": section.score,
      }));
      setRadarData(newRadarData);
    }
  }, [feedback]);

  const topSkills = feedback.content?.filter(item => item.score > 3) || [];
  const skillsToImprove = feedback.content?.filter(item => item.score <= 3) || [];

  if (!feedback) {
    return <div>{"Loading..."}</div>;
  }

  if (errorMsg !== "") {
    return (
      <>
        <Text align="left" size="300" weight="bold" color="red">{errorMsg}</Text>
      </>
    );
  }

  if (!isAuthorized) {
    return null; // Return null to render nothing if 403 error is encountered
  }

  if (errorMsg !== "") {
    return (
      <>
        <Text align="left" size="300" weight="bold" color="red">{errorMsg}</Text>
      </>
    );
  }

  return (
    <Box
      alignItems="center"
      height="100%"
      width="100%"
      justifyContent="center"
      padding={8}
      marginTop={6}
    >
      <Box marginTop={0} rounding={4} padding={4}>
        <Flex justifyContent="end" alignItems="center" gap={2}>
          <ButtonLink
            text="Back to List"
            size="md"
            onClick={() => navigate(`/candidateList/${query.get('jobId')}`)}
          />
        </Flex>
        <Flex justifyContent="start" alignItems="center" gap={2}>
          <img src="/icons/logo.png" width="40" height="40" alt="STK Logo" title="STK" />
          <Text align="left" size="600" weight="bold">
            Feedback for {feedback.candidateName}{" "}
            <span style={{ fontSize: "0.6em", fontWeight: "normal" }}>
              {feedback.userEmail}
            </span>
          </Text>
          
        </Flex>
        <Box marginTop={2} rounding={4} padding={2}>
          <Flex justifyContent="between" alignItems="center">
            <Text size="100" color="subtle">
              {feedback.overview}
            </Text>
          </Flex>
        </Box>
      </Box>
      <Divider />

      <Box
        padding={8}
        height="100%"
        alignItems="center"
        justifyContent="center"
      >

        <Flex alignItems="stretch" justifyContent="between" width="100%" gap={2}>

          <Box
            marginTop={0}
            padding={3}
            height={550}
          >
            <Box
              borderStyle="shadow"
              marginTop={0}
              rounding={4}
              padding={6}
            >
              <Box
                marginTop={0}
                rounding={4}
                padding={0}
              >
                <Text align="left" size="300" weight="bold">Overview Chart for {query.get('name')}'s interview</Text>
              </Box>
              <Radar radarData={radarData} />
            </Box>
          </Box>
          <Box
            marginTop={0}
            padding={3}
            flex="1"
            display="flex"
          // flexDirection="column"
          >
            <Accordion id="ModuleExample - default - 3" title="Product Sense Evaluation">
              {topSkills.length > 0 && (
                <>
                  <Text color='subtle'> Candidate's top skills </Text>
                  {topSkills.map((item, index) => (
                    <Box
                      padding={3}
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                      key={'top-' + index}
                    >
                      <Status type="ok" title={`${item.section} (Score: ${item.score})`} subtext={item.text} />
                    </Box>
                  ))}
                </>
              )}
              {skillsToImprove.length > 0 && (
                <>
                  <Text color='subtle'> Skills need to be improved </Text>
                  {skillsToImprove.map((item, index) => (
                    <Box
                      padding={3}
                      height="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="start"
                      key={'improve-' + index}
                    >
                      <Status type="problem" title={`${item.section} (Score: ${item.score})`} subtext={item.text} />
                    </Box>
                  ))}
                </>
              )}
            </Accordion>
          </Box>
        </Flex>

        <Box
          paddingY={6}
          paddingX={4}
          width="100%"
          borderStyle="shadow"
          rounding={4}
        >
          <Box
            marginBottom={4}
            rounding={4}
            paddingX={3}
          >
            <Text align="left" size="300" weight="bold">Chat History</Text>
          </Box>
          {feedback.chatHistory && feedback.chatHistory.map((chat, index) => (
            <Box key={index} paddingY={2} paddingX={4} display="flex" direction="column" marginBottom={1}>
              <Text size="lg">
                {chat.role === "assistant" ? "Assistant:" : `${feedback.candidateName}:`}
              </Text>
              <Text color='subtle' size="md" style={{ marginTop: '4px' }}>
                {chat.content}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
};


const AppWrapper = () => {
  const { isSignedIn } = useUser();
  return (
    <>
      <Header isAuthenticated={isSignedIn} />  
      <Feedback />  
    </>
  );  
};

export default AppWrapper;