import React from "react";
import { Box } from "gestalt";

export const CenteredBody = (props) => {
  return (
    <Box display="flex" justifyContent="center" padding={4}>
      <Box width={800} height={400} marginTop={4}>
        {props.children}
      </Box>
    </Box>
  );
}