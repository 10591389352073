import React, { useState, useEffect } from "react"; 
import { Box, Text, Flex, Heading, Link } from 'gestalt';

export const InvitationPage = ({
    jobInterviewLink
}) => {
    return(
        <Box display="flex" direction="column" width="100%" padding={3}>
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>  
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" /> 
                <div style={{ marginRight: '10px' }}></div> 
                <Heading>Interview Invitation</Heading>
            </Flex>

            <Box marginStart={4} marginEnd={4} padding={5} rounding={2} color="lightGray">
                <Text align="center" size="lg">Here is the link to your customized interview. Please send it to the candidates.</Text>
                <Text align="center" color="darkGray" overflow="normal" size="lg">
                    {jobInterviewLink} 
                </Text>
            </Box>
        </Box>
    );
};