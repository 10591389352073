import React, { useState, useEffect, useRef } from "react"; 
import { Box, Text, Flex, Heading, SelectList, IconButton, Button, Dropdown, TextArea } from 'gestalt';
import { ai_scn_instr, defaultQuestions } from './JobDesConsts'

export const QuestionList = ({ interviewQuestions, setInterviewQuestions }) => {
    
    const [questions, setQuestions] = useState(interviewQuestions.length > 0 ? interviewQuestions : [{ type: "default", content: "" }, { type: "default", content: "" }, { type: "default", content: "" }]);
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    useEffect(() => {
        const filteredQuestions = questions.filter(question => {
            return question.content.trim() !== "";
        });
        setInterviewQuestions(filteredQuestions);
    }, [questions, setInterviewQuestions]);

    const handleAddQuestion = (type) => {
        setQuestions([...questions, { type, content: "" }]);
    };

    const handleQuestionChange = (index, value, type) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index] =  {type, content: value };
        setQuestions(updatedQuestions);
    };
    

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };
    

    return (
        <Box display="flex" direction="column" width="100%" padding={3}>
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>
                <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" />
                <div style={{ marginRight: '10px' }}></div> 
                <Heading>Question List</Heading>
            </Flex>

            <Box display="flex" direction="column" alignItems="start" paddingY={5}>
                <Text>{`${ai_scn_instr}`}</Text>
            </Box>

            {questions.map((question, index) => (
                <Box key={index} display="flex" direction="column" alignItems="center" paddingX={0} paddingY={3} width="100%">
                    <Box display="flex" direction="row" alignItems="center" justifyContent="between" paddingX={2} width="100%">
                        <Text size="300" weight="bold">Question {index + 1}</Text>
                        {question.type === "default" ? (
                            <Box width="82%">
                                <SelectList
                                    id={`Question${index}`}
                                    onChange={({ value }) => handleQuestionChange(index, value, "default")}
                                    value={question.content}
                                    placeholder="Select a question"
                                    size="lg"
                                >
                                    {defaultQuestions.map((q, idx) => (
                                        <SelectList.Option key={idx} value={q} label={q} />
                                    ))}
                                </SelectList>
                            </Box>
                        ):(
                            <Box width="82%">
                                <TextArea
                                    id={`Question${index}`}
                                    onChange={(event) => handleQuestionChange(index, event.value, "custom")}
                                    value={question.content}
                                    placeholder="Enter a custom question"
                                    size="lg"
                                    rows={1}
                                />
                            </Box>
                        )}
                        
                        <IconButton
                            accessibilityLabel="Delete question"
                            icon="trash-can"
                            iconColor="red"
                            size="sm"
                            onClick={() => handleRemoveQuestion(index)}
                        />
                    </Box>
                </Box>
            ))}

            <Box display="flex" direction="row" alignItems="center" justifyContent="center" padding={5}>
                <Button
                    ref={anchorRef}
                    accessibilityLabel="Add new question"
                    iconEnd="arrow-down"
                    size="md"
                    // onClick={handleAddQuestion}
                    onClick={() => setOpen((prevVal) => !prevVal)}
                    text="Add More Questions"
                />
                {open && (
                    <Dropdown
                        anchor={anchorRef.current}
                        id="select new added question type"
                        onDismiss={() => setOpen(false)}
                    >
                        <Dropdown.Item
                            onSelect={() => handleAddQuestion("custom")}
                            option={{ value: 'customized question', label: 'Customized Question'}}
                        />
                        <Dropdown.Item
                            onSelect={() => handleAddQuestion("default")}
                            option={{ value: 'default question', label: 'Default Question'}}
                        />
                    </Dropdown>
                )}
            </Box>
        </Box>
    );
};
