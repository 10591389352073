import React from "react"; 
import { Box, Flex, Heading, TextArea, TextField, Text, SelectList } from "gestalt"; 
import { experienceLevelOption } from './JobDesConsts'
import { interviewOptions, languageOptions } from './JobDesConsts'

export const JobDescription = ({
    jobTitle, setJobTitle, jobDescription, setJobDescription, experienceLevel, setExperienceLevel, interviewRound, setInterviewRound, language, setLanguage
  }) => {
    return (
        <Box display="flex" direction="column" width="100%" padding={3} >
            <Flex direction="row" justifyContent="start" alignItems="center" gap={50} padding={50}>  
              <img src="/icons/logo.png" width="30" height="30" alt="STK Logo" title="STK" /> 
              <div style={{ marginRight: '10px' }}></div> 
              <Heading>Job Description</Heading>
            </Flex>
            <Flex direction="column" justifyContent="center" alignItems="center">
              <Box display="flex" direction="row" width="90%" justifyContent="start" alignItems="center" paddingY={5}>
                <Box paddingX={2} flex="grow" width="30%" style = {{flex: 1}}>
                  <Flex direction="column" gap={2}>
                    <Text size="300">Job Title</Text>
                    <TextField
                      id="JobTitle"
                      label="Title"
                      labelDisplay="hidden"
                      onChange={({value}) => setJobTitle(value)}
                      value={jobTitle} 
                      size="lg"
                    >
                    </TextField>
                  </Flex>
                </Box>
                
                <Box paddingX={2} flex="grow" width="40%" style = {{flex: 1}}>
                  <Flex direction="column" gap={2}>
                    <Text size="300">Experience Level</Text>
                    <SelectList
                      id="Experience Level"
                      onChange={({value}) => setExperienceLevel(value)}
                      value={experienceLevel}
                      size="lg"
                      placeholder="select experience level"
                    >
                      {experienceLevelOption.map((q, idx) => (
                          <SelectList.Option key={idx} value={q} label={q} />
                      ))}
                    </SelectList>
                  </Flex>
                </Box>

                <Box paddingX={2} flex="grow" width="30%" style = {{flex: 1}}>
                  <Flex direction="column" gap={2}>
                    <Text size="300">Interview Round</Text>
                    <SelectList
                      id="InterviewRound"
                      onChange={({value}) => {
                        const option = interviewOptions.find(option => option.value === value);
                        setInterviewRound(option || { value: '', label: '' });}}
                      value={interviewRound.value}
                      size="lg"
                      placeholder="select interview round"
                      style = {{width: "100%"}}
                    >
                      {interviewOptions.map((option, idx) => (
                        <SelectList.Option key={idx} value={option.value} label={option.label} />
                      ))}
                    </SelectList>           
                  </Flex>
                </Box>
                <Box paddingX={2} flex="grow" width="30%" style = {{flex: 1}}>
                  <Flex direction="column" gap={2}>
                    <Text size="300">Language</Text>
                    <SelectList
                      id="Language"
                      onChange={({value}) => {
                        const option = languageOptions.find(option => option.value === value);
                        setLanguage(option || { value: 'en', label: 'English' });}}
                      value={language.value}
                      size="lg"
                      placeholder="select Language"
                      style = {{width: "100%"}}
                    >
                      {languageOptions.map((option, idx) => (
                        <SelectList.Option key={idx} value={option.value} label={option.label} />
                      ))}
                    </SelectList>           
                  </Flex>
                </Box>            
              </Box>

              <Box display="flex" width="90%" justifyContent="center" alignItems="center" paddingY={3}>
                <Box paddingX={2} flex="grow">
                  <Flex direction="column" gap={2} flex="grow">
                    <Text size="300">Job description</Text>
                    <TextArea
                      id="JobDescription"
                      onChange={({value}) => setJobDescription(value)}
                      placeholder="Please enter your job description."
                      value={jobDescription} 
                      size="lg"
                      rows={10}
                    >
                    </TextArea>
                  </Flex>
                </Box>
              </Box>
            </Flex>
        </Box>
       
    );
}