import axios from "axios";

export async function getUser( isAuthenticated, user )  {

    let userData = {
        email: "",
        firstName: "",
        lastName: "",
        avatar: "",
        id: null,
        premium: false,
        premiumUntil: '',
        subscriptionLevel: '',
        subscriptionStatus: '',
    };

    if (isAuthenticated) {
        let userData = {
            email: user.emailAddress,
            firstName: user.firstName,
            lastName: user.lastName,
            id: null,
            premium: false,
            premiumUntil: '',
            subscriptionLevel: '',
            subscriptionStatus: '',
            
        };
        console.log('[INFO] Sign in with Email: ' + user.emailAddress)
        const apiUrl = process.env.REACT_APP_BACKEND_URL + "/api/auth/signup";
        try {
            let response = await axios.post(
                apiUrl, {
                email: user.emailAddress,
                firstName: user.firstName,
                lastName: user.lastName,
                }
            )
            userData.id = response.data.data.recruiterID;
            userData.premium = response.data.data.premium;
            userData.premiumUntil = new Date(response.data.data.premiumUntil);
            userData.subscriptionLevel = response.data.data.subscriptionLevel;
            userData.subscriptionStatus = response.data.data.subscriptionStatus;
            return userData;
        } catch(error) {
            console.error("Error saving message:", error);
        }
    } 

    return userData;
}
