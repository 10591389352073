import axios from "axios";  
import { getUser } from "../components/useAuth";
import { Box, Flex, Heading, Button, TextArea, TextField } from "gestalt";  
import React, { useState, useEffect, useRef } from "react";  
import { CenteredBody } from "../components/CenteredBody";  
import { Header } from "../components/Header";

const b2cUrl = process.env.REACT_APP_TOC_URL + "home?job="


const App = () => {  
  const [user, setUser] = useState(null);  
  const [title, setTitle] = useState("Product Manager");  
  const [jd, setJd] = useState("");  
  const [feedbackReq, setFeedbackReq] = useState("");  
  const [jobID, setJobID] = useState("")
  const [initialQ, setinitialQ] = useState("")
  const feedbackPromptExample = `
    Please return the evaluation on candidates' technical skill and communication skill in the feedback. Please return only a large json string as output, with same schema as:
     {
      content: 
        [
          {section:"Clarifying questions", score: 3, text: "The candidate asked relevant questions to clarify the product vision and user needs. However, they could have asked more probing questions to better understand the product requirements and constraints."}, 
          {section:"Business Acumen", score: 2, text: "The candidate asked relevant questions to clarify the product vision and user needs. However, they could have asked more probing questions to better understand the product requirements and constraints."}, 
          {section:"User Centricity", score: 4, text: "The candidate asked relevant questions to clarify the product vision and user needs. However, they could have asked more probing questions to better understand the product requirements and constraints."}, 
          {section:"Passion and Creativity", score: 5, text: "The candidate asked relevant questions to clarify the product vision and user needs. However, they could have asked more probing questions to better understand the product requirements and constraints."}, 
        ]
     }
  `
  
  const handleSubmit = () => {  
    const url = process.env.REACT_APP_BACKEND_URL + "/api/bData/jobCreation"
    axios.post(url, {jobTitle: title, interviewPrompt: jd, feedbackPrompt: feedbackReq, initialQ: initialQ, recruiterID: user.id})
    .then(response => {
        setJobID(response.data.data.jobID)
    })
    .catch(error => {
        console.error(error);
    });
  };  
  useEffect(async () => {
    let curUser = await getUser();
    setUser(curUser);
  }, []); // Empty dependency array means this effect runs once after the initial render

  
  return (  
    <>  
      <Box marginTop={6} rounding={4} padding={4} borderStyle="none" width="100%">  
        <Flex justifyContent="start" alignItems="center" gap={2}>  
          <img src="/icons/logo.png" width="40" height="40" alt="STK Logo" title="STK" />  
               <Heading>Job Post</Heading> 
        </Flex>   
      </Box>  
      
    <Box
      alignItems="center"
      alignSelf="center"  
      padding={4}  
      rounding={4}  
      marginTop={2}  
      justifyContent="center"
      borderStyle="shadow"
    >
        {
          jobID == "" ? 
          <>

            <Box
                width="100%"
                padding={4}  
                rounding={4}  
                marginTop={2}  
              >
              <TextField
                autoComplete="username"
                id="job-title"
                label="Job Title"
                onChange={({ value }) => {
                  setTitle(value);
                }}
                placeholder="Please enter your job title"
                type="text"
                value={title}
              />
            </Box>
            <Box 
                width="100%"
                padding={4}  
                rounding={4}  
                marginTop={2}  
            >
                <TextArea
                helperText="Please descript your job requirement and interview detail, such as what kind of questions you want to ask."
                id="best-practices-do-height"
                label="Interview Prompt"
                onChange={(e) => {
                    setJd(e.value);
                }}
                placeholder="Tell us about your experience, what you love, or what your passion for this job is."
                rows={5}
                value={jd}
                />
            </Box>

            <Box 
                width="100%"
                padding={4}  
                rounding={4}  
                marginTop={2}  
            >
                <TextArea
                helperText="Please include the first question here."
                id="best-practices-do-height"
                label="Initial Question"
                onChange={(e) => {
                    setinitialQ(e.value);
                }}
                placeholder="The first question will help to start a personal styled interview."
                rows={5}
                value={initialQ}
                />
            </Box>

            <Box 
                width="100%"
                padding={4}  
                rounding={4}  
                marginTop={2}  
            >
                <TextArea
                helperText="Please descript your feedback requirement, such as what kind of capabilities of candidates you want to include."
                id="best-practices-do-height"
                label="Feedback Prompt"
                onChange={(e) => {
                    setFeedbackReq(e.value);
                }}
                placeholder={feedbackPromptExample}
                rows={5}
                value={feedbackReq}
                />
            </Box>
            <Button color="blue" size="lg" text="Submit" onClick={handleSubmit}/>
          </>:
          <>
              <Heading align="center"  size="300">Here is the link to your customized interview. Please send it to the candidates. </Heading>

            <Box color="" padding={1}>
              <Heading align="center"  color="dark" size="500">
                 {b2cUrl+jobID}
              </Heading>
            </Box>
          </>
        }
      </Box>
    </>  
  );  
};  
  
export default () => {  
  return ( 
    <>
      <Header isAuthenticated={true}/> 
      <CenteredBody>  
          <App />  
      </CenteredBody>  
    </>
  );  
};  
