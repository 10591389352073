import { Box } from "gestalt";  
import { ResponsiveRadar } from '@nivo/radar'

export const Radar = (props) => (
        <Box    
            height={400}
            width="100%"
            minWidth={500}
            marginTop={6}
            justifyContent="center"
            alignItems="center"
        >
        
        <ResponsiveRadar
            data={props.radarData}
            keys={["score (0-5)"]}
            indexBy="section"
            valueFormat=">-.2f"
            maxValue={5}
            margin={{ top: 70, right: 100, bottom: 100, left: 100 }}
            borderColor={{ from: 'color' }}
            gridLabelOffset={36}
            dotSize={10}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            colors={{ scheme: 'nivo' }}
            blendMode="multiply"
            motionConfig="wobbly"
        />
        </Box>
)