import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '@clerk/clerk-react';
import { getUser } from "../components/getUser";
import { Link } from 'gestalt';
import { Header } from '../components/Header'; 
import { Button, Box, Modal, Text } from 'gestalt';

const App = () => {
  const [jobs, setJobs] = useState([]);
  const [userData, setUserData] = useState(null);  
  const { isSignedIn, user, isLoaded } = useUser();
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const rootUrl = process.env.REACT_APP_BACKEND_URL;
  const toCRootUrl = process.env.REACT_APP_TOC_URL;

  useEffect( () => {
    const fetchJobs = async () => {
      if (isLoaded){
        try {
          let curUser = {emailAddress:user.primaryEmailAddress.emailAddress, firstName: user.firstName, lastName: user.lastName}
          let userInfo = await getUser(isSignedIn, curUser);
          setUserData(userInfo);
          const response = await axios.post(rootUrl + "/api/bData/jobList", {recruiterID: userInfo.id});
          setJobs(response.data.data.jobs);
        } catch (error) {
          console.error('Error fetching job list:', error);
        }
      }
    };

    fetchJobs();
  }, [isSignedIn, user, isLoaded, rootUrl]);

  // Handle delete confirmation modal
  const handleDelete = (jobId) => {
    setSelectedJobId(jobId);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      // Send delete request to backend
      await axios.post(rootUrl + "/api/bData/jobDeletion", { jobId: selectedJobId, userId: userData.id });

      // Remove the deleted job from the state
      const updatedJobs = jobs.filter(job => job.JobID !== selectedJobId);
      setJobs(updatedJobs);
      setShowModal(false);
    } catch (error) {
      console.error('Failed to delete job:', error);
    }
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Job List</h1>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Job Title</th>
            <th style={tableHeaderStyle}>Date Published</th>
            <th style={tableHeaderStyle}>Candidate Invited</th>
            <th style={tableHeaderStyle}>Copy Link</th>
            <th style={tableHeaderStyle}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job) => (
            <tr key={job.JobID}>
              <td style={tableCellStyle}>
              <Link
                display="inlineBlock"
                externalLinkIcon="default"
                href={`/candidateList/${job.JobID}`}
                target="blank"
              >
                {job.Title}
              </Link>
              </td>
              <td style={tableCellStyle}>{job.DatePublished}</td>
              <td style={tableCellStyle}>{job.CandidatesCount}</td>
              <td style={tableCellStyle}>
              <CopyButton jobId={job.JobID} link={`${toCRootUrl}/interview?job=${job.JobID}&round=${job.InterviewRound}&language=${job.Language}`} />
              </td>
              <td style={tableCellStyle}>
                <Button color="red" text="Delete" size="sm" onClick={() => handleDelete(job.JobID)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Confirmation Modal */}
      {showModal && (
        <Modal
          accessibilityModalLabel="Confirm Delete Job"
          heading="Delete Job?"
          onDismiss={() => setShowModal(false)}
          footer={
            <Box display="flex" justifyContent="end" marginEnd={4}>
              <Box padding={2}>
                <Button text="Cancel" onClick={() => setShowModal(false)} inline />
              </Box>
              <Box padding={2}>
                <Button text="Delete" color="red" onClick={confirmDelete} inline />
              </Box>
            </Box>
          }
          size="sm"
        >
          <Box paddingX={4} paddingY={2}>
            <Text>This action cannot be undone.</Text>
          </Box>
        </Modal>
      )}
    </div>
  );
};

const CopyButton = ({ jobId, link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(link); // Copy link to clipboard
    setCopied(true); // Set copied state to true
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div>
      {copied ? (
        <span>Copied!</span>
      ) : (
        <button onClick={handleCopyLink}>📋</button>
    )}
    </div>
  );
};

const tableHeaderStyle = {
  padding: '10px',
  backgroundColor: '#f2f2f2',
  fontWeight: 'bold',
  textAlign: 'left',
};

const tableCellStyle = {
  padding: '10px',
};

const AppWrapper = () => {
    const { isSignedIn } = useUser();
  
    return (
      <>
        <Header isAuthenticated={isSignedIn} />
        <App />
      </>
    );
  };
  
export default AppWrapper;
  